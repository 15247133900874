import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const StyledInput = styled.input`
  ${fonts.montserratBold};
  padding: 16px 24px;
  border: 1px solid ${colors.greyDark};
  border-radius: 8px;
  outline: none;
  width: 100%;
  margin-top: 20px;
  background: ${colors.green};
  border: 1px solid ${colors.green};
  color: ${colors.white};
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
`;

const InputSubmit = (props) => {
  return (
    <StyledInput type={"submit"} {...props} />
  );
};

export default InputSubmit;
