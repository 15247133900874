import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";

const StyledErrorMessage = styled.div`
  margin-top: 12px;
  color: ${colors.red};
  
  @media(min-width: ${breakpoints.md}) {
    margin-top: 20px;
  }
`;

const ErrorMessage = ({children}) => {
  return (
    <StyledErrorMessage>
      {children}
    </StyledErrorMessage>
  );
};

export default ErrorMessage