import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts";
import breakpoints from "../../styles/breakpoints";

const StyledTitle = styled.h1`
  ${fonts.montserratRegular};
  
  @media(min-width: ${breakpoints.md}) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const SmallH1 = ({children}) => {
  return (
    <StyledTitle>
      {children}
    </StyledTitle>
  );
};

export default SmallH1;
