import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts";

const StyledLabel = styled.label`
  ${fonts.montserratMedium};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 20px;  
`;

const Label = ({children}) => {
  return (
    <StyledLabel>
      {children}
    </StyledLabel>
  );
};

export default Label;
