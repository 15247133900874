import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const StyledInput = styled.input`
  ${fonts.montserratMedium};
  padding: 16px 24px;
  border: 1px solid ${colors.greyDark};
  border-radius: 8px;
  outline: none;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  
  :-webkit-autofill,
  :-webkit-autofill:hover, 
  :-webkit-autofill:focus, 
  :-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 65px ${colors.white} inset !important;
    color: ${colors.black};
    font-size: 14px;
    line-height: 24px;
    ${fonts.montserratMedium};
  }
  
  :-webkit-autofill {
    -webkit-text-fill-color: ${colors.black};
    color: ${colors.black};
    font-size: 14px;
    line-height: 24px;
    ${fonts.montserratMedium};
  }
`;

const InputText = (props) => {
  return (
    <StyledInput type={"text"} {...props} />
  );
};

export default InputText;
