import React, {useState} from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import shopify from "../backend/shopify";
import styled from "styled-components";
import breakpoints from "../styles/breakpoints";
import InputText from "../components/Core/InputText";
import InputSubmit from "../components/Core/InputSubmit";
import Label from "../components/Core/Label";
import logo from "../resources/img/logo/logo.svg";
import ErrorMessage from "../components/Core/ErrorMessage";
import SmallH1 from "../components/Core/SmallH1";

const LoginContent = styled.div`
  width: 100%;
  max-width: 480px;
  margin: auto;
  padding: 80px 0 64px 0;
  
  form {
    margin-top: 80px;
    
    label {
      display: block;
    }
  }
  
  @media(min-width: ${breakpoints.md}) {
    padding: 160px 0 128px 0;
  } 
`;

const LogoContainer = styled.div`  
  display: block;
  text-align: center;
  width: 100%;
  margin-bottom: 80px;
`;

const LogoImage = styled.img`
  height: 50px;
  position: relative;
  display: block;
  margin: auto;
  display: inline-block;

  @media(min-width: ${breakpoints.md}) {
    height: 60px;
  }
`;

const TitleContainer = styled.div`
  text-align: center;
  margin: auto;
  
  @media(min-width: ${breakpoints.md}) {
    max-width: 400px;
  }
`;

const LoginPage = () => {
  const [domain, setDomain] = useState(null);
  const [hasError, setHasError] = useState(false);

  const loginRedirect = (e) => {
    e.preventDefault();

    shopify.login(domain)
      .then(({data}) => {
        const {url} = data;

        if (url) {
          window.location.href = url;
        } else {
          setHasError(true);
        }
      });
  };

  return (
    <Layout login={true}>
      <SEO title="Login"
           description="Login to your Shopify store to access the app."/>
      <LoginContent>
        <LogoContainer>
          <LogoImage src={logo} alt={"Theme Deploy"}/>
        </LogoContainer>
        <TitleContainer>
          <SmallH1>Log in to your Shopify store</SmallH1>
        </TitleContainer>
        <form onSubmit={(e) => loginRedirect(e, domain)}>
          <Label>Store domain</Label>
          <InputText name={"domain"}
                     onChange={(e) => setDomain(e.target.value)}
                     placeholder={"your-store.myshopify.com"}/>
          <InputSubmit value={"Login"}/>
        </form>
        {hasError && <ErrorMessage>Please verify the store URL and try again!</ErrorMessage>}
      </LoginContent>
    </Layout>
  );
};

export default LoginPage;
